import * as React from 'react';

function PricingPage() {
    const user = JSON.parse(localStorage.getItem("user"));


    return (
        <stripe-pricing-table
            pricing-table-id="prctbl_1OoMF3Ln6islyFl13ewkG8kr"
            publishable-key="pk_test_vKvViwjJO8QCLrhBLaeQjESJ00KJfOCyx2"
            client-reference-id={user ? user.id : ''}
            customer-email={user ? user.email : ''}
        >
        </stripe-pricing-table>
    );
}

export default PricingPage;
