import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';

//Redux
import { useSelector } from 'react-redux';

//API  
import { fetchCurrentUserInfos, fetchCurrentUserOrders } from "../../api/userAPI";
import { fetchParticipantByUserId } from "../../api/groupOrdersParticipantsAPI"
import { fetchSubscription } from "../../api/subsciptionAPI"

//Elements
import Button from '../elements/button/Button';
import PopupConfirm from "../elements/popup/popup-confirm/PopupConfirm";
import PopupFridge from '../elements/popup/popup-fridge/PopupFridge';

//Icons
import { FaRegUser, FaRegStar } from "react-icons/fa";
import { TbTruckDelivery } from "react-icons/tb";
import { IoRestaurantOutline } from "react-icons/io5";
import { PiBowlFood } from "react-icons/pi";

//toast
import toast from 'react-hot-toast';

// Styles
import '../../views/admin-dashboard/AdminDashboard.css';

function ClientNavbar({ onSelectSection }) {

    const navigate = useNavigate();
    const { subscriptionExists, subscriptionInfos } = useSelector(state => state.subscription);
    console.log('subscriptionExists', subscriptionExists)


    const [userInfo, setUserInfo] = useState({});
    const [unpaidOrdersExist, setUnpaidOrdersExist] = useState(false);
    const [unpaidOrderNum, setUnpaidOrderNum] = useState(null);
    const [readyOrderExist, setReadyOrderExist] = useState(false);
    const [showPopupFridge, setShowPopupFridge] = useState(false);
    const [readyOrderNum, setReadyOrderNum] = useState(null);
    const [groupOrderExist, setGroupOrderExist] = useState(false);



    useEffect(() => {
        const fetchUserInfo = async () => {
            try {
                const data = await fetchCurrentUserInfos();
                setUserInfo(data);
            } catch (error) {
                console.error(
                    "Erreur lors de la récupération des informations de l'utilisateur:",
                    error
                );
            }
        };

        fetchUserInfo();
    }, []);



    useEffect(() => {
        const fetchUserOrderAndParticipant = async () => {
            try {
                const data = await fetchCurrentUserOrders();
                const userId = userInfo.id;
                const isParticipant = await fetchParticipantByUserId(userId);

                if (data && data.length > 0) {
                    const lastOrder = data[0];
                    console.log('lastOrder', lastOrder);
                    console.log('isParticipant', isParticipant);

                    // Mise à jour des états pour les commandes non payées
                    setUnpaidOrdersExist(lastOrder.payment_status === false);
                    setUnpaidOrderNum(lastOrder.order_num);

                    // Définir readyOrderExist si le statut est 'accepted' et que l'utilisateur n'est pas un participant de groupe
                    if (lastOrder.status === "accepted" && (!isParticipant || lastOrder.id !== isParticipant.order_id)) {
                        setReadyOrderExist(true);
                        setReadyOrderNum(lastOrder.order_num);
                    } else {
                        setReadyOrderExist(false);
                    }

                    // Gérer la visibilité du bouton basée sur la commande de groupe et le paiement
                    if (isParticipant && lastOrder.id === isParticipant.order_id && lastOrder.payment_status !== "paid") {
                        setGroupOrderExist(true); // Afficher le bouton si la commande de groupe n'est pas payée
                    } else {
                        setGroupOrderExist(false); // Ne pas afficher le bouton autrement
                    }

                } else {
                    setUnpaidOrdersExist(false);
                    setReadyOrderExist(false);
                    setGroupOrderExist(false);
                }
            } catch (error) {
                console.error("Erreur lors de la récupération des commandes et des informations du participant :", error);
            }
        };

        if (userInfo.id) {
            fetchUserOrderAndParticipant();
        }
    }, [userInfo.id]);

    const handleActionSuccess = () => {
        setReadyOrderExist(false);
    };

    const handleButtonClick = () => {
        if (unpaidOrdersExist && unpaidOrderNum) {
            localStorage.setItem("order_num", unpaidOrderNum);
            navigate("/checkout");
        } else if (readyOrderExist) {
            setShowPopupFridge(true);
        } else {
            navigate("/home");
        }
    };

    const handleSectionClick = (section) => {
        onSelectSection(section);
        navigate(`/account/${section}`);
    };

    return (
        <nav className="admin-navbar">
            <div className="admin-title">
                <h1>Bonjour {userInfo.firstname || "Stranger"}</h1>
            </div>
            <Link className='admin-navbar-links' onClick={() => handleSectionClick("profil")}>
                <FaRegUser className='admin-navbar-icons' size={15} />
                Profil
            </Link>
            <Link className='admin-navbar-links' onClick={() => handleSectionClick("orders")}>
                <TbTruckDelivery className='admin-navbar-icons' size={15} />
                Commandes
            </Link>
            {groupOrderExist && (
                <Link className='admin-navbar-links' onClick={() => handleSectionClick("group-orders")}>
                    <TbTruckDelivery className='admin-navbar-icons' size={15} />
                    Suivi de commande
                </Link>
            )}

            <Link className='admin-navbar-links' onClick={() => handleSectionClick("subscriptions")}>
                <FaRegStar className='admin-navbar-icons' size={15} />
                Abonnements
            </Link>
            {subscriptionExists && subscriptionInfos.subscription_type === "pro_chef" && (
                <>
                    <Link className='admin-navbar-links' onClick={() => handleSectionClick("stocks")}>
                        <IoRestaurantOutline className='admin-navbar-icons' size={15} />
                        Produits
                    </Link>
                </>
            )}
            {subscriptionExists && (
                <>
                    <Link className='admin-navbar-links' onClick={() => handleSectionClick("recipes")}>
                        <IoRestaurantOutline className='admin-navbar-icons' size={15} />
                        Recettes
                    </Link>
                    <Link className='admin-navbar-links' onClick={() => handleSectionClick("menulist")}>
                        <PiBowlFood className='admin-navbar-icons' size={15} />
                        Menu Maker
                    </Link>
                </>
            )}
            <div className="client-action">
                {(unpaidOrdersExist || readyOrderExist) && (
                    <button
                        className="btn-navbar"
                        onClick={handleButtonClick}
                    >
                        {unpaidOrdersExist
                            ? "Payer la commande"
                            : readyOrderExist
                                ? "Ouvrir le frigo"
                                : "Commander un repas"}
                    </button>
                )}
            </div>

            {showPopupFridge && (
                <PopupFridge
                    isOpen={showPopupFridge}
                    onConfirm={() => setShowPopupFridge(false)}
                    onActionSuccess={handleActionSuccess}
                    readyOrderNum={readyOrderNum}
                />
            )}
        </nav>
    );
}

export default ClientNavbar;
