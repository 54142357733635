import React, { useState } from 'react';
import Popup from 'reactjs-popup';
import Switch from '@mui/material/Switch';

// Icons
import { AiOutlineCloudUpload } from 'react-icons/ai';

// API
import { uploadRecipeImage } from "../../../../api/recipesAPI";

//toast
import toast from 'react-hot-toast';

// Styles
import './PopupRecipe.css';

function PopupRecipe({ trigger, onCreate }) {

    const userId = JSON.parse(localStorage.getItem("user"))?.id;

    //Local States
    const [imageName, setImageName] = useState("");
    const [formData, setFormData] = useState({
        name: "",
        description: "",
        price: "0.0",
        barcode: "",
        visibility: false,
        partId: "1",
        image: null,
        prep_time: "",
        steps: "",
        user_id: userId,
        portions: 1,
    });

    //Parts options
    const partsId = [
        {
            value: '1',
            label: 'Entrées',
        },
        {
            value: '2',
            label: 'Plats',
        },
        {
            value: '3',
            label: 'Desserts',
        },
        {
            value: '4',
            label: 'Boissons',
        },
    ];

    //Visibility options
    const visibilityOptions = [
        {
            value: '1',
            label: 'Visible',
        },
        {
            value: '0',
            label: 'Invisible',
        },
    ];

    const handleSubmit = async (event, close) => {
        event.preventDefault();

        if (!formData.image) {
            toast.error('Veuillez choisir une image pour continuer');
            return;
        }

        try {
            // Convertir explicitement 'name' en chaîne de caractères avant d'envoyer les données
            onCreate({ ...formData, name: String(formData.name) });

            // Réinitialise le formulaire et ferme le popup
            setFormData({ ...formData });
            close();
        } catch (error) {
            console.error('Error during recipe creation or image upload:', error);
            toast.error('Erreur lors de la création de la recette ou du téléchargement de l\'image');
        }
    };



    const handleImageChange = (e) => {
        const file = e.target.files[0];
        setFormData({ ...formData, image: file });
        setImageName(file ? file.name : "");
    };

    const [checked, setChecked] = useState(false);

    const handleToggle = () => {
        const newValue = !checked;
        setChecked(newValue);
        setFormData({ ...formData, visibility: newValue ? 1 : 0 });
    };

    return (
        <Popup
            trigger={trigger}
            position="right center"
            modal
            contentStyle={{ zIndex: 99999 }}
        >
            {(close) => (
                <div className="popup-confirm-content">
                    <div className="popup-title">
                        <h1>Nouvelle recette</h1>
                    </div>
                    <div className="popup-recipe-form">
                        <form onSubmit={(e) => handleSubmit(e, close)}>
                            <input
                                id="outlined-basic"
                                label="Nom de la recette"
                                variant="outlined"
                                type="text"
                                className="popup-recipe-name"
                                placeholder="Nom de la recette"
                                color="success"
                                value={formData.name}
                                onChange={(e) =>
                                    setFormData({ ...formData, name: e.target.value })
                                }
                                required
                            />
                            <input
                                id="outlined-basic"
                                label="Code-barre de la recette"
                                variant="outlined"
                                type="text"
                                className="popup-recipe-barcode"
                                placeholder="Code-barre"
                                color="success"
                                value={formData.barcode}
                                onChange={(e) =>
                                    setFormData({ ...formData, barcode: e.target.value })
                                }
                                required
                            />
                            <input
                                id="outlined-textarea"
                                label="Description de la recette"
                                placeholder="Description"
                                color="success"
                                className="popup-recipe-description"
                                value={formData.description}
                                onChange={(e) =>
                                    setFormData({ ...formData, description: e.target.value })
                                }
                                multiline
                            />
                            <label htmlFor="price">Prix de la recette (€)</label>
                            <input
                                id="outlined-number"
                                name="price"
                                label="Prix de la recette"
                                type="number"
                                variant="outlined"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                className="popup-recipe-price"
                                placeholder="Prix de la recette"
                                color="success"
                                value={formData.price}
                                onChange={(e) =>
                                    setFormData({ ...formData, price: e.target.value })
                                }
                                required
                            />
                            <label htmlFor="portions">Nombre de portions</label>
                            <input
                                id="outlined-number"
                                name="portions"
                                label="Nombre de portions"
                                type="number"
                                variant="outlined"
                                step="1"
                                min="1"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                className="popup-recipe-portions"
                                placeholder="Portions"
                                color="success"
                                value={formData.portions}
                                onChange={(e) =>
                                    setFormData({ ...formData, portions: e.target.value })
                                }

                            />
                            <div className="popup-recipe-part">
                                <select
                                    id="partId"
                                    className="popup-recipe-partId"
                                    color="success"
                                    select
                                    label="Parts"
                                    value={formData.partId}
                                    onChange={(e) =>
                                        setFormData({ ...formData, partId: e.target.value })
                                    }
                                    required
                                >
                                    {partsId.map((option) => (
                                        <option key={option.value} value={option.value}>
                                            {option.label}
                                        </option>
                                    ))}
                                </select>
                            </div>
                            <label htmlFor="prep_time">Temps de préparation (min)</label>
                            <input
                                id="outlined-number"
                                name="prep_time"
                                type="number"
                                variant="outlined"
                                step="1"
                                min="0"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                className="popup-recipe-price"
                                placeholder="Temps de préparation"
                                color="success"
                                value={formData.prep_time}
                                onChange={(e) =>
                                    setFormData({ ...formData, prep_time: e.target.value })
                                }
                                required
                            />
                            <textarea
                                name="steps"
                                id="steps"
                                cols="30"
                                rows="10"
                                placeholder="Étapes de préparation"
                                value={formData.steps}
                                onChange={(e) =>
                                    setFormData({ ...formData, steps: e.target.value })
                                }
                            ></textarea>
                            <Switch
                                checked={checked}
                                onChange={handleToggle}
                                className="edit-btn"
                                color="success"
                                inputProps={{ 'aria-label': 'toggle visibility' }}
                            />
                            <label htmlFor="recipe-image" className="custom-file-upload btn-border btn-ms">
                                <AiOutlineCloudUpload />
                                <p>{imageName ? imageName : "Ajouter une image"}</p>
                                <input
                                    type="file"
                                    id="recipe-image"
                                    accept="image/*"
                                    className="custom-file-input"
                                    onChange={handleImageChange}
                                />
                            </label>
                            <div className="popup-actions flex-center">
                                <button type="submit" className="btn-full btn-sm">
                                    Créer
                                </button>
                                <button className="btn-border btn-sm" onClick={close}>
                                    Annuler
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            )}
        </Popup>
    );
}

export default PopupRecipe;
