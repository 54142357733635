import React, { useEffect, useState } from 'react';

//API
import { fetchCurrentUserOrders } from "../../api/userAPI";

//MUI
import Box from '@mui/joy/Box';
import Button from '@mui/joy/Button';
import Card from '@mui/joy/Card';
import CardContent from '@mui/joy/CardContent';
import CardActions from '@mui/joy/CardActions';
import Chip from '@mui/joy/Chip';
import Typography from '@mui/joy/Typography';

//toast
import toast from 'react-hot-toast';

// Styles
import '../../views/admin-dashboard/AdminDashboard.css';

function ClientImpact({ userInfo }) {
    const [orders, setOrders] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    // Pour stocker les nombres d'items par partId
    const [countPart1, setCountPart1] = useState(0);
    const [countPart2, setCountPart2] = useState(0);
    const [countPart3, setCountPart3] = useState(0);

    // Pour stocker le poids total consommé
    const [totalWeight, setTotalWeight] = useState(0);

    useEffect(() => {
        const fetchOrders = async () => {
            try {
                const ordersData = await fetchCurrentUserOrders();
                console.log('ordersData:', ordersData); // Log des données récupérées
                setOrders(ordersData);

                // Initialisation des compteurs pour chaque partId et du poids total
                let part1Count = 0;
                let part2Count = 0;
                let part3Count = 0;
                let weight = 0;

                // Parcourir chaque commande et chaque order_item pour compter les partsId et calculer le poids total
                ordersData.forEach((order, orderIndex) => {
                    order.order_items.forEach((item, itemIndex) => {
                        // Ajout de vérifications pour les données nulles ou undefined
                        if (item.recipe && item.recipe.partId !== undefined) {
                            if (item.recipe.partId === 1) {
                                part1Count += item.quantity;
                                weight += item.quantity * 150; // 150g pour chaque entrée
                            }
                            if (item.recipe.partId === 2) {
                                part2Count += item.quantity;
                                weight += item.quantity * 400; // 400g pour chaque plat
                            }
                            if (item.recipe.partId === 3) {
                                part3Count += item.quantity;
                                weight += item.quantity * 100; // 100g pour chaque dessert
                            }
                        }
                    });
                });

                // Mise à jour des états avec les compteurs et le poids total
                setCountPart1(part1Count);
                setCountPart2(part2Count);
                setCountPart3(part3Count);
                setTotalWeight(weight / 1000); // Convertir en kg
            } catch (error) {
                setError("Erreur lors de la récupération des commandes");
                console.error('Erreur lors de la récupération des commandes:', error); // Log des erreurs
                toast.error("Erreur lors de la récupération des commandes");
            } finally {
                setLoading(false);
            }
        };

        fetchOrders();
    }, []);

    // Calcul des valeurs en fonction du poids total
    const veganDay = Math.round(totalWeight / 1.2); // Calcul des jours de veganisme
    const carbonDioxyde = veganDay * 2.74; // Calcul du CO2 économisé
    const waterSaved = veganDay * 1800; // Calcul de l'eau économisée

    if (loading) {
        return <div>Chargement des commandes...</div>;
    }

    if (error) {
        return <div>{error}</div>;
    }

    return (
        <>
            <div className='impact-cards'>
                <Card
                    variant="solid"
                    color="success"
                    invertedColors
                    sx={{
                        boxShadow: 'lg',
                        width: 200,
                        maxWidth: '100%',
                        overflow: 'auto',
                        resize: 'horizontal',
                    }}
                >
                    <div>
                        <Typography level="h1">
                            {veganDay}{' '}
                        </Typography>
                    </div>
                    <CardContent>
                        <Typography level="title-ml">jours de véganisme</Typography>
                    </CardContent>
                </Card>

                <Card
                    variant="solid"
                    color="success"
                    invertedColors
                    sx={{
                        boxShadow: 'lg',
                        width: 200,
                        maxWidth: '100%',
                        overflow: 'auto',
                        resize: 'horizontal',
                    }}
                >
                    <div>
                        <Typography level="h1">
                            {carbonDioxyde}{' '}
                            <Typography level="h1">
                                kg
                            </Typography>
                        </Typography>
                    </div>
                    <CardContent>
                        <Typography level="title-ml">de CO2 économisés</Typography>
                    </CardContent>
                </Card>

                <Card
                    variant="solid"
                    color="success"
                    invertedColors
                    sx={{
                        boxShadow: 'lg',
                        width: 200,
                        maxWidth: '100%',
                        overflow: 'auto',
                        resize: 'horizontal',
                    }}
                >
                    <div>
                        <Typography level="h1">
                            {waterSaved}{' '}
                            <Typography level="h1">
                                L
                            </Typography>
                        </Typography>
                    </div>
                    <CardContent>
                        <Typography level="title-ml">d'eau économisés</Typography>
                    </CardContent>
                </Card>
            </div>
        </>
    );
}

export default ClientImpact;
