import { useState, useEffect } from "react";

//MUI
import { styled } from "@mui/material/styles";
import Badge from "@mui/material/Badge";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import TablePagination from "@mui/material/TablePagination";

//Components
import PopupInfos from "../elements/popup/popup-infos/PopupInfos";

//API
import { fetchCurrentUserOrders } from "../../api/userAPI";

//Icons
import InfoIcon from '@mui/icons-material/Info';

export default function OrdersTable({
  setUnpaidOrdersExist,
  setUnpaidOrderNum,
  setReadyOrderExist,
  setReadyOrderNum,
}) {

  //Local States
  const [orders, setOrders] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [openInfosPopup, setOpenInfosPopup] = useState(false);
  const [selectedOrder, setSelectedOrder] = useState(null);

  // Fields for order data
  const orderFields = [
    { name: 'order_num', label: 'Numéro de commande' },
    { name: 'status', label: 'Statut' },
    { name: 'payment_status', label: 'Statut de paiement' },
    { name: 'userId', label: 'User Id' },
  ];


  /**
   * Formats a date string to the format "dd/mm/yyyy hh:mm".
   * @param {string} dateString - The date string to format.
   * @returns {string} The formatted date string.
   */
  function formatDateWithTime(dateString) {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();
    const hours = String(date.getHours()).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, "0");

    return `${day}/${month}/${year} ${hours}:${minutes}`;
  }

  /**
   * Fetches the current user's orders and sets them in state.
   */
  useEffect(() => {
    const token = JSON.parse(localStorage.getItem("user"))?.accessToken;
    if (token) {
      const getUserOrders = async () => {
        try {
          const fetchedUserOrders = await fetchCurrentUserOrders(token);
          setOrders(fetchedUserOrders);



          if (fetchedUserOrders.length > 0 && !fetchedUserOrders[0].payment_status) {

            setUnpaidOrdersExist(true);
            setUnpaidOrderNum(fetchedUserOrders[0].order_num);
          } else {
            setUnpaidOrdersExist(false);
          }


          if (fetchedUserOrders.length > 0 && fetchedUserOrders[0].status === "accepted") {
            setReadyOrderExist(true);
            setReadyOrderNum(fetchedUserOrders[0].order_num);
          } else {
            setReadyOrderExist(false);
          }
        } catch (error) {
          console.error("Erreur lors de la récupération des commandes:", error);
        }
      };

      getUserOrders();
    }
  }, [setUnpaidOrdersExist, setUnpaidOrderNum, setReadyOrderExist, setReadyOrderNum]);


  /**
   * Formats the price from cents to euros (e.g., 810 -> 8.10).
   * @param {number} priceInCents - The price in cents.
   * @returns {string} The formatted price string.
   */
  function formatPrice(priceInCents) {
    return (priceInCents / 100).toFixed(2);
  }

  /**
   * Custom styled table cell for the orders table.
   * @param {object} theme - The MUI theme object.
   * @return {object} The styled table cell.
   */
  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: "rgb(50, 89, 62)",
      color: theme.palette.common.white,
      fontWeight: "bold",
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));

  /**
   * Custom styled table row for the orders table.
   * @param {object} theme - The MUI theme object.
   * @return {object} The styled table row.
   */
  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
    "&:last-child td, &:last-child th": {},
  }));

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const slicedOrders = orders.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);

  const handleInfosClick = (order) => {
    setSelectedOrder(order);
    setOpenInfosPopup(true);

  };

  return (
    <div className="client-orders">
      <div className="client-orders-title flex-center">
        <h2>Mon historique des commandes</h2>
      </div>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="orders table">
          <TableHead>
            <TableRow>
              <StyledTableCell>Numéro de commande</StyledTableCell>
              <StyledTableCell align="center">Prix</StyledTableCell>
              <StyledTableCell align="center">Statut</StyledTableCell>
              <StyledTableCell align="center">
                Statut de paiement
              </StyledTableCell>
              <StyledTableCell align="center">Commandé le</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {slicedOrders.map((order) => (
              <StyledTableRow key={order.id}>
                <TableCell style={{ fontSize: 13 }}>
                  <div style={{ display: "flex", gap: ".5rem", alignItems: "center" }}>
                    <InfoIcon style={{ fontSize: 15, color: "#B5B5B5", cursor: "pointer" }} onClick={() => handleInfosClick(order)} />
                    {" " + order.order_num}
                    <PopupInfos
                      open={openInfosPopup}
                      onClose={() => setOpenInfosPopup(false)}
                      data={selectedOrder}
                      fields={orderFields}
                      title="Informations de commande"
                    />
                  </div>
                </TableCell>
                <TableCell align="center">
                  {order.price ? formatPrice(order.price) : "N/A"}€
                </TableCell>

                <TableCell align="center">
                  {order.status ? order.status : "N/A"}
                </TableCell>
                <TableCell align="center">
                  {order.payment_status ? (
                    <Badge color="success" badgeContent="Payé"></Badge>
                  ) : (
                    <Badge color="error" badgeContent="Echec"></Badge>
                  )}
                </TableCell>
                <TableCell align="center">
                  {order.orderedAt
                    ? formatDateWithTime(order.orderedAt)
                    : "N/A"}
                </TableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        component="div"
        count={orders.length}
        page={page}
        onPageChange={handleChangePage}
        rowsPerPage={rowsPerPage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </div>
  );
}
