import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';

import toast from "react-hot-toast";

//API
import { verifyUser, resetPassword } from '../../api/userAPI';

//Elements
import Button from '../../components/elements/button/Button';

//Style
import './Reset.css'

function ResetPassword() {

    let { id: userId, token } = useParams();

    const navigate = useNavigate();

    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [user, setUser] = useState(null);


    useEffect(() => {
        const fetchUser = async () => {
            try {
                const userData = await verifyUser(userId, token);
                setUser(userData);
            } catch (error) {
                toast.error(
                    error.message
                );
            }
        };

        fetchUser();
    }, [userId, token]);

    const validatePassword = (password) => {
        const hasEightCharacters = password.length >= 8;
        const hasUpperCase = /[A-Z]/.test(password);
        const hasNumber = /\d/.test(password);
        const hasSymbol = /[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]+/.test(password);

        return hasEightCharacters && hasUpperCase && hasNumber && hasSymbol;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!validatePassword(password)) {
            toast.error(
                "Le mot de passe doit contenir au moins 8 caractères, une majuscule, un chiffre et un symbole."
            );
            return;
        }

        if (password !== confirmPassword) {
            toast.error(
                "Les mots de passe ne correspondent pas."
            );
            return;
        }

        try {
            const response = await resetPassword(userId, token, password, confirmPassword);
            toast.success(
                "Le mot de passe a été réinitialisé. Veuillez vous connecter"
            );
            navigate('/login')
        } catch (error) {

            toast.error(
                "Erreur lors de la réinitialisation du mot de passe. Veuillez réessayer."
            );

        }
    };


    return (
        <div className="reset-password-container flex-center">
            <h2>Réinitialisation de mot de passe</h2>
            <form onSubmit={handleSubmit}>
                <div>
                    <input
                        type="password"
                        id="password"
                        placeholder="Nouveau mot de passe"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        className="reset-password-input"
                        required
                    />
                </div>
                <div>
                    <input
                        type="password"
                        id="confirm-password"
                        placeholder="Confirmez le nouveau mot de passe"
                        value={confirmPassword}
                        onChange={(e) => setConfirmPassword(e.target.value)}
                        className="reset-password-input"
                        required
                    />
                </div>
                <div className='reset-password-action flex-center'>
                    <Button className="btn-full" type="submit" text="Réinitialiser le mot de passe" />
                </div>
            </form>
        </div>
    );
}

export default ResetPassword;
