import { useState, useRef, useEffect } from "react";

//Images
import UnLogo from "../../assets/F_SDG_logo_UN_emblem_square_trans_WEB-300x249-1.webp"
import UberEats from "../../assets/9.webp"
import Deliveroo from "../../assets/8.webp"
import Gagny from "../../assets/1.webp"
import IleDeFrance from "../../assets/logo-region-ile-de-france.webp"
import VeganAsso from "../../assets/2.webp"
import Objectif2 from "../../assets/telechargement-3.webp"
import Objectif3 from "../../assets/F-WEB-Goal-03.webp"
import Objectif8 from "../../assets/F_SDG-goals_icons-individual-rgb-08.webp"
import Objectif10 from "../../assets/F-WEB-Goal-10.webp"
import Objectif11 from "../../assets/telechargement-4.webp"
import Objectif12 from "../../assets/F_SDG-goals_icons-individual-rgb-12.webp"
import Objectif14 from "../../assets/F_SDG-goals_icons-individual-rgb-14.webp"
import Objectif15 from "../../assets/F-WEB-Goal-15.webp"


//MUI Icons
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import LinkedInIcon from '@mui/icons-material/LinkedIn';

//Styles
import "./Footer.css"


function Footer({ userInfo }) {

    return (
        <div className="footer">
            <div className="footer-section">
                <div className="footer-social">
                    <div className="footer-text">
                        <p>
                            Améliorer significativement le bilan RSE des entreprises, tout en contribuant au bien-être des collaborateurs avec une alimentation gourmande, saine et durable
                        </p>
                    </div>
                    <div className="footer-social-icons">
                        <a href="https://fr-fr.facebook.com/mamossaFood/" target="_blank" rel="noopener noreferrer"><FacebookIcon /></a>
                        <a href="https://www.instagram.com/mamossapointcom/?hl=fr" target="_blank" rel="noopener noreferrer"><InstagramIcon /></a>
                        <a href="https://www.linkedin.com/company/mamossa/" target="_blank" rel="noopener noreferrer"><LinkedInIcon /></a>
                    </div>
                    <div className="footer-delivery-icons">
                        <a href="https://mamossa.com/commander-vos-repas/" target="_blank" rel="noopener noreferrer"><img src={UberEats} alt="Logo de UberEats" className="delivery-logos" /></a>
                        <a href="https://mamossa.com/commander-vos-repas/" target="_blank" rel="noopener noreferrer"><img src={Deliveroo} alt="Logo de Deliveroo" className="delivery-logos" /></a>
                    </div>

                </div>
                <div className="footer-links">

                    <a href="https://mamossa.com/comment-ca-marche/" className="link" target="_blank" rel="noopener noreferrer">Comment ça marche ?</a>
                    <a href="/https://app.mamossa.com/" className="link" target="_blank" rel="noopener noreferrer">Commander mon repas</a>
                    <a href="https://app.mamossa.com/login" className="link" target="_blank" rel="noopener noreferrer">Mon compte</a>
                    <a href="https://mamossa.com/rejoindre-lequipe/" className="link" target="_blank" rel="noopener noreferrer">Rejoindre l'équipe</a>
                    <a href="https://mamossa.com/a-propos/" className="link" target="_blank" rel="noopener noreferrer">A propos</a>
                    <a href="https://mamossa.com/politique-de-cookies-ue/" className="link" target="_blank" rel="noopener noreferrer">Politique de cookies (UE)</a>
                    <a href="https://mamossa.com/cgv/" className="link" target="_blank" rel="noopener noreferrer">CGV / CGU</a>
                    <a href="https://mamossa.com/mentions-legales/" className="link" target="_blank" rel="noopener noreferrer">Mentions Légales</a>
                    <a href="https://mamossa.com/politique-de-confidentialite-2/" className="link" target="_blank" rel="noopener noreferrer">Politique de confidentialité</a>
                    <a href="https://mamossa.com/credits/" className="link" target="_blank" rel="noopener noreferrer">Crédit</a>
                    <a href="https://mamossa.com/blog/" className="link" target="_blank" rel="noopener noreferrer">Blog</a>


                </div>
                <div className="footer-partners">
                    <h3>Soutenu par</h3>
                    <div style={{ display: "flex", gap: "1rem", alignItems: "flex-start" }}>
                        <a href="https://www.iledefrance.fr/" target="_blank" rel="noopener noreferrer"><img src={Gagny} alt="Logo de Gagny" className="gagny-logo" /></a>
                        <a href="https://www.gagny.fr/" target="_blank" rel="noopener noreferrer"><img src={IleDeFrance} alt="Logo Region Ile de France" className="idf-logo" /></a>
                    </div>

                    <a href="https://www.vegetarisme.fr/" target="_blank" rel="noopener noreferrer"> <img src={VeganAsso} alt="Logo de Association Végétarienne de France" className="vegan-logo" /></a>
                </div>
            </div>
            <a href="https://www.un.org/sustainabledevelopment/fr/objectifs-de-developpement-durable/" target="_blank" rel="noopener noreferrer">
                <div className="footer-section-bottom">
                    <div className="un-logo">
                        <img src={UnLogo} alt="Logo de l'ONU" />

                    </div>

                    <div className="un-goals">
                        <img src={Objectif2} alt="Objectif Onu" className="un-objetcif-logo" />
                        <img src={Objectif3} alt="Objectif Onu" className="un-objetcif-logo" />
                        <img src={Objectif8} alt="Objectif Onu" className="un-objetcif-logo" />
                        <img src={Objectif10} alt="Objectif Onu" className="un-objetcif-logo" />
                        <img src={Objectif11} alt="Objectif Onu" className="un-objetcif-logo" />
                        <img src={Objectif12} alt="Objectif Onu" className="un-objetcif-logo" />
                        <img src={Objectif14} alt="Objectif Onu" className="un-objetcif-logo" />
                        <img src={Objectif15} alt="Objectif Onu" className="un-objetcif-logo" />
                    </div>

                </div>
            </a>
            <div className="footer-section"></div>
        </div>
    );
}

export default Footer;
