import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

//Redux
import { setSubscriptionExists, resetSubscription } from '../../features/subscriptions/subscriptionSlice';

import { fetchCurrentUserInfos } from "../../api/userAPI";
import { fetchSubscription } from "../../api/subsciptionAPI"

import "../../views/client-dashboard/ClientDashboard.css"


function ClientManageSubscription() {

    const dispatch = useDispatch();
    const { subscriptionExists, subscriptionInfos } = useSelector(state => state.subscription);

    const [userInfo, setUserInfo] = useState({});

    useEffect(() => {
        const fetchUserInfo = async () => {
            try {
                const data = await fetchCurrentUserInfos();
                setUserInfo(data);
            } catch (error) {
                console.error(
                    "Erreur lors de la récupération des informations de l'utilisateur:",
                    error
                );
            }
        };

        fetchUserInfo();
    }, []);




    const formatDate = (dateString) => {

        const date = new Date(dateString);

        const day = date.getDate();
        const month = date.getMonth() + 1;
        const year = date.getFullYear();

        return `${day < 10 ? '0' + day : day}/${month < 10 ? '0' + month : month}/${year}`;
    };


    return (

        <section className='client-manage-subscription'>

            <div className='flex-center'>
                <h2 className='client-manage-subscription-title' >Votre abonnement Menu Maker</h2>
            </div>
            <div className='flex-center'>
                <p className='client-manage-subscription-description'>
                    Vous êtes membre premium Mamossa depuis le {formatDate(subscriptionInfos?.createdAt)}. Vous souscrivez à l'abonnement {subscriptionInfos?.subscription_type === "menu_maker" ? "Menu Maker" : subscriptionInfos?.subscription_type}.
                </p>
            </div>
            <div className='flex-center'>
                <a href="https://billing.stripe.com/p/login/test_3cs9EfeWEfql7ZKeUU" target="_blank" rel="noopener noreferrer">
                    <button className='btn-full'>Gérer mon abonnement</button>
                </a>

            </div>

        </section>


    );
}

export default ClientManageSubscription;