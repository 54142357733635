import { useState, useRef, useEffect } from "react";
import DefautProfilPicture from "../../assets/profil/profil-picture.webp";
import Avatar from '@mui/joy/Avatar';
import EditIcon from '@mui/icons-material/Edit';
import Button from "../elements/button/Button";
import PopupUpdate from "../elements/popup/popup-update/PopupUpdate";
import ClientAllergies from "./ClientAllergies";
import ClientImpact from "./ClientImpact";
import { uploadProfilPicture } from "../../api/userAPI"; // Assurez-vous d'importer votre fonction API

// Toast
import toast from 'react-hot-toast';


function ClientInfos({ userInfo }) {
  const [userInfos, setUserInfos] = useState(userInfo);
  const [openUpdatePopup, setOpenUpdatePopup] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const [profilPicture, setProfilPicture] = useState("");

  console.log("userInfo", userInfo)

  function formatDate(dateString) {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Les mois sont indexés à partir de 0
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  }

  useEffect(() => {
    if (userInfo.profilePicture) {
      setProfilPicture(userInfo.profilePicture);
    }
  }, [userInfo]);



  // Référence pour l'input file caché
  const fileInputRef = useRef(null);

  const handleEditIconClick = () => {
    // Ouvre l'input file caché lorsque l'utilisateur clique sur l'icône d'édition
    fileInputRef.current.click();
  };

  function handleUpdateConfirm(updatedData) {
    setUserInfos(updatedData);
    setOpenUpdatePopup(false); // Close the popup after update
  }


  const handleFileChange = async (event) => {
    const file = event.target.files[0];
    if (file) {
      try {

        const modifiedFileName = file.name.replace(/\s+/g, '_');


        const renamedFile = new File([file], modifiedFileName, { type: file.type });

        const userId = userInfo.id;
        const response = await uploadProfilPicture(userId, renamedFile);
        toast.success("Votre photo de profil sera mise à jour")

        const updatedUserInfo = {
          ...userInfos,
          profilePicture: `${process.env.REACT_APP_API_URL}${response.profilePictureUrl}`
        };

        setUserInfos(updatedUserInfo);
        setProfilPicture(`${process.env.REACT_APP_API_URL}${response.profilePictureUrl}`);

        console.log('Picture response', response.message);
      } catch (error) {
        console.error("Erreur lors de l'upload de la photo de profil", error);
      }
    }
  };





  const handleInfosClick = (userInfo) => {
    setSelectedUser(userInfo);
    setOpenUpdatePopup(true);
  };

  const profilFields = [
    { name: 'firstname', label: 'Prénom' },
    { name: 'lastname', label: 'Nom' },
    { name: 'email', label: 'Email' },
  ];

  return (
    <div className="client-infos-container container">
      <div className="picture-mobile flex-center">
        <div className="client-infos-picture-mobile">
          <img
            src={`${process.env.REACT_APP_API_URL}${userInfo.picture}` ? `${process.env.REACT_APP_API_URL}${userInfo.picture}` : DefautProfilPicture}
            alt="Client Profil"
            className="client-picture"
          />
        </div>
      </div>

      <div className="client-infos flex-center">
        <div className="client-infos-picture">
          <img
            src={`${process.env.REACT_APP_API_URL}${userInfo.picture}` ? `${process.env.REACT_APP_API_URL}${userInfo.picture}` : DefautProfilPicture}
            alt="Client Profil"
            className="client-picture"
          />
        </div>
        <div className="client-picture-actions">
          <EditIcon sx={{ color: "#fff", fontSize: "12px", cursor: "pointer" }} onClick={handleEditIconClick} />
          {/* Input file caché */}
          <input
            type="file"
            ref={fileInputRef}
            style={{ display: "none" }}
            accept="image/*"
            onChange={handleFileChange}
          />
        </div>
      </div>

      <div className="client-infos-perso flex-center">
        <h2>Mes Informations</h2>
        <div className="client-full-name">
          {userInfos.firstname} {userInfo.lastname}
        </div>
        <div className="client-mail">{userInfo.email}</div>
        <div className="client-inscription">
          Inscrit depuis le {formatDate(userInfo.createdAt)}
        </div>
      </div>

      <div className="client-infos-allergies">
        <div className="client-allergies-title">
          <h2 className="flex-center">Mes allergies</h2>
        </div>
        <ClientAllergies />
        <div className="client-infos-action flex-center">
          <Button
            className="btn-border btn-ms"
            text="Modifier mes informations"
            onClick={() => handleInfosClick(userInfo)}
          />
          {openUpdatePopup && (
            <PopupUpdate
              open={openUpdatePopup}
              onClose={() => setOpenUpdatePopup(false)}
              initialData={selectedUser}
              fields={profilFields}
              title="Modifier le profil"
              onConfirm={handleUpdateConfirm}
            />
          )}
        </div>
        <div className="flex-center">
          <ClientImpact />
        </div>

      </div>





    </div>
  );
}

export default ClientInfos;
