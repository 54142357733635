import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";

//Redux
import { useDispatch, useSelector } from 'react-redux';
import { setSubscriptionExists, resetSubscription } from '../../features/subscriptions/subscriptionSlice';

//API
import { fetchCurrentUserInfos, fetchCurrentUserOrders } from "../../api/userAPI";
import { fetchSubscription } from "../../api/subsciptionAPI";

//MUI Components
import Typography from '@mui/joy/Typography';
import Button from '@mui/joy/Button';

//Components
import PopupFridge from "../../components/elements/popup/popup-fridge/PopupFridge";

// Styles
import "../admin-dashboard/AdminDashboard.css";

function ClientMobile() {

    const { id } = useParams();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const subscriptionExists = useSelector((state) => state.subscription.subscriptionExists);
    const subscriptionInfos = useSelector((state) => state.subscription.subscriptionInfos);

    const [userInfo, setUserInfo] = useState({});
    const [unpaidOrdersExist, setUnpaidOrdersExist] = useState(false);
    const [unpaidOrderNum, setUnpaidOrderNum] = useState(null);
    const [readyOrderExist, setReadyOrderExist] = useState(false);
    const [showPopupFridge, setShowPopupFridge] = useState(false);
    const [readyOrderNum, setReadyOrderNum] = useState(null);

    // Récupérer les infos de l'utilisateur
    useEffect(() => {
        const fetchUserInfo = async () => {
            try {
                const data = await fetchCurrentUserInfos();
                setUserInfo(data);
            } catch (error) {
                console.error("Erreur lors de la récupération des informations de l'utilisateur:", error);
            }
        };

        fetchUserInfo();
    }, []);

    // Récupérer l'abonnement de l'utilisateur
    useEffect(() => {
        const fetchUserSubscription = async () => {
            if (userInfo && userInfo.id) {  // Assurez-vous que userInfo.id est disponible
                try {
                    const subscriptionData = await fetchSubscription(userInfo.id);
                    if (subscriptionData) {
                        dispatch(setSubscriptionExists({
                            exists: true,
                            subscriptionInfos: subscriptionData,
                            active: subscriptionData.active
                        }));
                    } else {
                        dispatch(resetSubscription());
                    }
                } catch (error) {
                    console.error("Erreur lors de la récupération de l'abonnement:", error);
                    dispatch(resetSubscription());
                }
            }
        };

        if (userInfo.id) {
            fetchUserSubscription();
        }
    }, [userInfo, dispatch]);

    const handleUserClick = () => {
        navigate("/account/profil");
    };

    const handleOrderClick = () => {
        navigate("/account/my-orders");
    };

    const handleGroupOrderClick = () => {
        navigate("/account/orders-tracking");
    };

    const handleSubscriptionClick = () => {
        if (subscriptionExists) {
            navigate("/account/manage-subscriptions");
        } else {
            navigate("/account/subscriptions");
        }
    };

    const handleRecipeClick = () => {
        navigate("/recipes");
    };

    const handleMenuMakerClick = () => {
        navigate("/account/menulist");
    };

    const handleStockClick = () => {
        navigate("/account/stock");
    };

    const handleButtonClick = () => {
        if (unpaidOrdersExist && unpaidOrderNum) {
            localStorage.setItem("order_num", unpaidOrderNum);
            navigate("/checkout");
        } else if (readyOrderExist) {
            setShowPopupFridge(true);
        } else {
            navigate("/home");
        }
    };

    const handleActionSuccess = () => {
        setReadyOrderExist(false);
    };

    return (
        <>
            <div className="admin-mobile-header">
                <h2>Hello {userInfo.firstname} 👋</h2>
                <Typography level="body-ms" textColor={"#f5f5f5"} paddingLeft={'1rem'}>Que voulez-vous faire aujourd'hui?</Typography>
            </div>
            <div className="admin-mobile-actions">
                <Button sx={buttonStyle} variant="outlined" size="lg" onClick={handleUserClick}>
                    Mon Profil
                </Button>
                <Button sx={buttonStyle} variant="outlined" size="lg" onClick={handleOrderClick}>
                    Mes Commandes
                </Button>
                <Button sx={buttonStyle} variant="outlined" size="lg" onClick={handleGroupOrderClick}>
                    Suivi de commandes
                </Button>
                <Button sx={buttonStyle} variant="outlined" size="lg" onClick={handleSubscriptionClick}>
                    Mes Abonnements
                </Button>
                {subscriptionExists && subscriptionInfos.active && subscriptionInfos.subscription_type === "pro_chef" && (
                    <>
                        <Button sx={buttonStyle} variant="outlined" size="lg" onClick={handleStockClick}>
                            Les Produits
                        </Button>
                    </>
                )}
                {subscriptionExists && subscriptionInfos.active && (
                    <>
                        <Button sx={buttonStyle} variant="outlined" size="lg" onClick={handleRecipeClick}>
                            Les Recettes
                        </Button>
                        <Button sx={buttonStyle} variant="outlined" size="lg" onClick={handleMenuMakerClick}>
                            Menu Maker
                        </Button>
                    </>
                )}
                <Button color="danger" sx={{ width: "270px", height: '50px', marginBottom: "3rem" }} variant="outlined" size="lg"
                    onClick={handleButtonClick}>
                    {unpaidOrdersExist
                        ? "Payer la commande"
                        : readyOrderExist
                            ? "Ouvrir le frigo"
                            : "Commander un repas"}
                </Button>
            </div>
            {showPopupFridge && (
                <PopupFridge
                    isOpen={showPopupFridge}
                    onConfirm={() => setShowPopupFridge(false)}
                    onActionSuccess={handleActionSuccess}
                    readyOrderNum={readyOrderNum}
                />
            )}
        </>
    );
}

export default ClientMobile;

const buttonStyle = {
    borderColor: "#006d26",
    color: "#006d26",
    width: "270px",
    height: '50px'
};
