import React, { useState, useEffect } from "react";

//API
import { getItemsByOrderId } from "../../../../api/orderItemsAPI";
import { fetchRecipeById } from "../../../../api/recipesAPI";

//Style
import './PopupInfos.css';
import { findUserbyId } from "../../../../api/userAPI";
import { LocalLaundryService } from "@mui/icons-material";

export default function PopupInfos({ open, onClose, data, title }) {

    //LocalStates
    const [items, setItems] = useState([]);
    const [loadingItems, setLoadingItems] = useState(false);
    const [errorItems, setErrorItems] = useState(null);
    const [recipeDetailsList, setRecipeDetailsList] = useState([]);
    const [loadingRecipe, setLoadingRecipe] = useState(false);
    const [errorRecipe, setErrorRecipe] = useState(null);
    const [user, setUser] = useState(null);

    // Fetch items by order ID and update state
    useEffect(() => {
        const fetchItems = async () => {
            try {
                setLoadingItems(true);
                const orderId = data.id;
                const itemsData = await getItemsByOrderId(orderId);

                setItems(itemsData);
                setLoadingItems(false);
            } catch (error) {
                setErrorItems(error);
                setLoadingItems(false);
            }
        };
        const fetchUser = async () => {
            try {

                const userData = await findUserbyId(data.userId);


                setUser(userData);
            } catch (error) {
                setErrorItems(error);
                setLoadingItems(false);
            }
        }
        if (open && data) {
            fetchItems();
            fetchUser();
        }
    }, [open, data]);

    // Fetch recipe's details by recipe ID and update state
    useEffect(() => {
        const fetchRecipeDetails = async (recipeId) => {
            try {
                setLoadingRecipe(true);

                if (recipeId !== null) {
                    const recipeData = await fetchRecipeById(recipeId);
                    setRecipeDetailsList((prevList) => [...prevList, recipeData]);
                }
                setLoadingRecipe(false);
            } catch (error) {
                setErrorRecipe(error);
                setLoadingRecipe(false);
            }
        };

        items.forEach((item) => {
            fetchRecipeDetails(item.recipeId);
        });
    }, [items]);

    const isAdmin = localStorage.getItem('roles') === "admin";



    return (
        <>
            {open && (
                <>
                    <div className="overlay" onClick={onClose}></div>
                    <div className="infos-modal">
                        <div className="edit-modal-title">
                            <h2>{title}</h2>
                        </div>
                        <div className="infos-modal-content">
                            <h4 className="infos-modal-title">Récapitulatif de la commande</h4>
                            <div className="items-list">
                                {loadingItems && <p>Loading items...</p>}
                                {errorItems && <p>Error: {errorItems.message}</p>}
                                {!loadingItems && !errorItems && (
                                    <ul className="order-items-list">
                                        {items.map((item, index) => (
                                            <li key={item.id}>
                                                <strong>{recipeDetailsList[index]?.name}</strong> {" x" + item.quantity}
                                            </li>
                                        ))}
                                    </ul>
                                )}

                            </div>
                            {isAdmin ? (
                                <div className="items-list">
                                    <ul className="order-items-list">{user?.firstname}</ul>
                                </div>
                            ) : (
                                <div></div>
                            )}
                            <div className="infos-modal-action">
                                <button
                                    className="btn-border"
                                    variant="outlined"
                                    onClick={onClose}
                                >
                                    Fermer
                                </button>
                            </div>

                        </div>
                    </div>
                </>
            )}
        </>
    );
}
